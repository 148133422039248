.Map {
    height: 100vh;
    width: 100%;
    .leaflet-container {
        height: 100vh;
        width: 100%;
    }
    .title {
        color: #1962a9;
        font-weight: 700;
        border-bottom: 1px gray solid;
        margin-bottom: 10px;
    }
    .item-title {
        font-weight: bold;
    }
}