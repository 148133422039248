.DataTable {
    background-color: white;
    position: absolute;
    z-index: 2002;
    left: calc(30vw + 30px);
    overflow: auto;
    max-width: calc(70vw - 100px);
    min-width: 40vw;
    min-height: 30vh;
    top: 54px;
    // height: calc(100vh - 100px);
    border: 0.5px solid rgba(255, 255, 255, 0.25);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    .title {
        // background: darkgrey;
        line-height: 25px;
        display: flex;
        padding: 10px 25px 8px;
        .btn-export {
            width: 120px;
            color: #2F3193;
            font-weight: bold;
            cursor: pointer;
            u {
                vertical-align: top;
            }
        }
        .content {
            // width: fit-content;
            width: calc(100% - 130px);
            padding: 0 20px;
            text-align: center;
            font-weight: bold;
            .content--select-box {
                max-width: 400px;
                outline: none;
                width: 60%;
                margin-left: 20px;
                padding: 5px;
                border: 0.25px solid #ababab;
                box-sizing: border-box;
                box-shadow: 1px 2px 2px rgb(0 0 0 / 15%);
                border-radius: 4px;
            }
        }
        .btn-close {
            text-align: right;
            width: 30px;
            cursor: pointer;
        }
    }
    .next {
        margin-top: 20px;
        cursor: pointer;
        position: absolute;
        top: 40%;
        right: 10px;
    }
    .previous {
        margin-top: 20px;
        cursor: pointer;
        position: absolute;
        top: 40%;
        left: 10px;
    }
    .item {
        overflow: auto;
        margin: 20px 50px;
        // height: calc(30vh - 50px);

        table {
            margin: auto;
            border-spacing: 0;
            thead { 
                th {
                    padding: 0;
                    margin: 0;
                    background-color: #E3E3E3;
                    padding: 5px 10px;
                }
            }
            tbody {
                td {
                    padding: 10px;
                }
                tr:hover {
                    background-color: #ededed;
                }
            }
            .date-time {
                min-width: 160px;
            }
        }
    }
}
.DataCamera {
    min-width: 40vw;
    background-color: white;
    position: absolute;
    z-index: 2002;
    left: calc(30vw + 30px);
    overflow: auto;
    top: 50px;
    border: 0.5px solid rgba(255, 255, 255, 0.25);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    font-size: 13px;
    height: 380px;
    .title {
        // background: darkgrey;
        line-height: 25px;
        display: flex;
        padding: 10px 25px 8px;
        .content {
            font-size: 21px;
            width: 90%;
            text-align: center;
            color: #2f3193;
            font-weight: bold;
        }
        .btn-close {
            width: 10%;
            text-align: right;
            padding-right: 5px;
            cursor: pointer;
        }
    }
    .content-popup { 
        padding: 5px 20px;
    }
}
.DataChart {
    min-width: 50vw;
    background-color: white;
    position: absolute;
    z-index: 2002;
    left: calc(30vw + 30px);
    overflow: auto;
    top: 50px;
    border: 0.5px solid rgba(255, 255, 255, 0.25);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    font-size: 13px;
    .title-popup {
        margin-bottom: 20px;
        padding: 8px 25px 2px;
        line-height: 25px;
        display: flex;
        // background: darkgrey;
        .content {
            font-size: 21px;
            width: 90%;
            text-align: center;
            color: #2f3193;
            font-weight: bold;
        }
        .btn-close {
            width: 10%;
            text-align: right;
            padding-right: 5px;
            cursor: pointer;
        }
    }
    .content-popup {
        max-height: calc(100vh - 150px);
        overflow: auto;
        .item {
            padding: 0 50px;
        }
        .item-chart {
            text-align: center;
            border: 1px solid rgb(224, 224, 224);
            background-color: rgb(244, 244, 244);
            border: 1px solid #eeeeee;
            border-top: none;
            margin-bottom: 25px;
            padding: 15px;
            border-radius: 15px;
            box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
        }
    }
}

@media only screen and (max-width: 1600px) {
    .DataTable {
        min-width: 50vw;
    }
}