.Menu {
    nav {
        transition: 0.7s;
        position: absolute;
        z-index: 2000;
        bottom: 0;
        height: 100%;
        overflow: auto;
        max-width: 30vw;

        .logo {
            display: flex;

            .img-logo {
                width: 25%;

                img {
                    vertical-align: bottom;
                }
            }

            .spacing {
                width: 65%;
            }

            .icon-close {
                width: 10%;
                cursor: pointer;
                text-align: right;

                svg {
                    vertical-align: middle;
                }
            }
        }

        .title-menu {
            padding: 10px 0;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #303030;
            display: flex;

            .previous,
            .next {
                width: 30px;
                cursor: pointer;

                svg {
                    padding-top: 7px;
                }
            }

            .center {
                width: calc(100% - 60px);
                font-weight: bold;
                line-height: 40px;
                color: #34A853;
                font-size: 16px;
            }

            .group-select {
                label {
                    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                    font-weight: bold;
                }

                #group {
                    outline: none;
                    width: 60%;
                    margin-left: 20px;
                    padding: 5px;
                    border: 0.25px solid #ababab;
                    box-sizing: border-box;
                    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.15);
                    border-radius: 4px;
                }
            }
        }
        .general-information {
            .title {
                .w-50 {
                    width: 50%;
                }
                display: flex;
                color: #FFF;
                background-color: #4285F4;
                font-size: 14px;
                font-weight: 700;
                line-height: 32px;
                border: 0.25px solid #E5E5E5;
                height: 38px;
                padding-left: 11px;
                span {
                    font-weight: 400;
                }
                select {
                    color: #909090;
                    background: #FFFFFF;
                    border: 0.25px solid #303030;
                    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.15);
                    border-radius: 4px;
                    font-style: italic;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 14px;
                    margin-left: 5px;
                    min-width: 100px;
                    padding: 3px;
                }
            }
            .content {
                padding-bottom: 10px;
                .content-title {
                    text-align: center;
                    font-style: italic;
                    color: #303030;
                    padding: 15px 0;
                    font-weight: bold;

                }
                .item:last-child {
                    border: none;
                }
                .item {
                    text-align: center;
                    border-right: 1px dashed #34A853;
                    .icon {
                        height: 25px;
                        line-height: 30px;
                    }
                    .sensor {
                        padding-top: 4px;
                        color: #4285F4;
                        font-size: 13px;
                    }
                    .box {
                        color: #EA4335;
                        font-weight: bold;
                        font-size: 28px;
                        sub {
                            font-weight: 300;
                            font-size: 11px;
                            font-style: italic;
                            color: #606060;
                            margin-left: -10px;
                        }
                    }
                }
            }
        }
        .list-zone .title {
            color: #FFF;
            background-color: #4285F4;
            font-size: 14px;
            font-weight: 700;
            line-height: 32px;
            border: 0.25px solid #E5E5E5;
            height: 38px;
            padding-left: 11px;
        }

        .zone {
            display: flex;

            .name {
                width: calc(100% - 50px);
                font-weight: bold;
                font-size: 14px;
            }

            .count {
                width: 50px;
                font-size: 14px;
                line-height: 20px;
            }
        }

        .list-group {
            .group {
                font-size: 14px;
            }
        }

        .province {
            border-bottom: 1px solid #909090;
            color: #303030;
            font-weight: bold;
        }

        .province-selected {
            border-bottom: 1px solid #34A853;
            color: #34A853;
        }

    }

    .MenuIcon {
        transition: 1s;
        padding: 10px 10px 6px;
        cursor: pointer;
        position: absolute;
        z-index: 2000;
        top: 5px;
        margin-left: 10px;
        border: 0.2px solid rgba(24, 24, 24, 0.25);
        box-sizing: border-box;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        background-clip: padding-box;
        text-align: center;
        background-color: white;
    }

    .GroupInfo {
        .info {
            .title {
                margin: 0;
                padding: 10px;
                width: 100%;
                color: #FFF;
                background-color: #4285F4;
                font-size: 14px;
                font-weight: 700;
                line-height: 16px;
                border: 0.25px solid #E5E5E5;
            }

            .item {
                max-height: calc(100vh - 320px);
                min-height: calc(100vh - 450px);
                overflow: auto;
            }
            .info-item {
                margin: 0;
                font-size: 12px;
                font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                padding: 8px 10px 8px 25px;

                div {
                    padding: 0;
                }

                .value {
                    color: #303030;
                    font-weight: bold;
                    overflow: hidden;
                }
            }
        }

        .options {
            .title {
                margin: 0;
                padding: 10px;
                width: 100%;
                color: #FFF;
                background-color: #4285F4;
                font-size: 14px;
                font-weight: 700;
                line-height: 16px;
                border: 0.25px solid #E5E5E5;
            }

            .box {
                text-align: center;
                display: flex;

                .item {
                    cursor: pointer;
                    margin: 10px auto;
                    padding: 10px 0;
                    width: 28%;
                    font-size: 13px;
                    color: #4285F4;
                    // border: 2.5px solid #909090;
                    // box-sizing: border-box;
                    // box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
                    // border-radius: 8px;

                    .note {
                        padding-top: 5px;
                    }
                }

                // .selected-data {
                //     border: 2.5px solid #2F3193;
                //     box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
                //     border-radius: 8px;
                // }

                .item-data {
                    padding-top: 5px;

                    .note {
                        padding-top: 0;
                    }
                }
            }
        }
    }

    .Chart {
        .item {
            padding: 5px 10px 30px 10px;
        }

        .title {
            margin: 0;
            padding: 10px;
            width: 100%;
            color: #2F3193;
            background-color: #E3E3E3;
            font-size: 14px;
        }
    }


}

.leaflet-control-zoom {
    display: none;
}

@media only screen and (max-width: 1600px) {
    .Menu {
        nav {
            .title-menu {
                .group-select {
                    font-size: 15px;
                    #group {
                        width: 56%;
                        margin-left: 5px;
                    }
                }
            }
        }
        .GroupInfo {
            .options {
                .box {
                    .item {
                        width: 31%;
                    }
                }
            }
        }
    }

}
.actived-button{
    background-color: #2F3193;
    .noted{
        color: white
    }
}
