.HorizonToolBar {
    position: absolute;
    z-index: 2000;
    top: 7vh;
    right: 75px;
    .content {
        display: flex;
        .icon-item {
            cursor: pointer;
            background: #FFFFFF;
            border: 0.2px solid rgba(24, 24, 24, 0.25);
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
            border-radius: 8px;
            width: 30px;
            height: 30px;
            margin-right: 4px;
            svg {
                height: 18px;
                margin: 5px
            }
        }
    }
}