.leaflet-ruler {
  width: 30.5px;
  height: 30.5px;
  border-radius: 8px;
  text-align: center;
  background-color: #FFFFFF;
  background-image: url("../../../../Shared/Components/IconHorizonToolBar/polygon.svg");
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: center;
  /* background-size: cover; */
}


.leaflet-ruler:hover {
  background-color: #FFFFFF;
  cursor: pointer;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {  }
/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1366px) {  
  .leaflet-bar {
    position: absolute;
    z-index: 9999;
    top: 1.8vh;
    right: 4.4vh; 
    display: flex;
    transition: 0.7s;
    border: 0.2px solid rgba(24, 24, 24, 0.25) !important;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3) !important;
  }
}

@media (min-width: 1600px) {  
  .leaflet-bar {
    position: absolute;
    z-index: 9999;
    top: 2.55vh;
    right: 3.7vh; 
    display: flex;
    transition: 0.7s;
    border: 0.2px solid rgba(24, 24, 24, 0.25) !important;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3) !important;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1920px) {  
  .leaflet-bar {
    position: absolute;
    z-index: 9999;
    top: 3.3vh;
    right: 3.1vh; 
    display: flex;
    transition: 0.7s;
    border: 0.2px solid rgba(24, 24, 24, 0.25) !important;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3) !important;
  }
}
