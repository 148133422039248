body {
    margin: 0;
    padding: 0;
    .App {
        display: flex;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    }
    .leaflet-top {
        top: 30px;
    }
    iframe {
        display: none;
        z-index: 1;
    }
    .t-c {
        text-align: center;
    }
}