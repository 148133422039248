.ToolBar {
    position: absolute;
    z-index: 2000;
    top: 15vh;
    display: flex;
    right: 20px;
    transition: 0.7s;
    // .toolbar-close {
    //     color: #606060;
    //     position: absolute;
    //     z-index: 2001;
    //     right: 40px;
    //     top: 5px;
    //     cursor: pointer;
    // }
    .content {
        height: fit-content;
        border: 0.2px solid rgba(24, 24, 24, 0.25);
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        .icon-item {
            width: 30px;
            height: 30px;
            line-height: 42px;
            background-clip: padding-box;
            text-align: center;
            background-color: white;
            border-bottom: 0.5px solid #4285F4;
            cursor: pointer;
            svg {
                height: 18px;
                margin: 5px;
                color: #4285F4;
            }
        }
        .icon-item:first-child {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
        .icon-item:last-child {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            border-bottom: none;
        }
        .bgr-active {
            background-color: #4285F4;
        }
    }
    .toolbar-display {
        margin-right: 7px;
        border: 0.25px solid rgba(255, 255, 255, 0.25);
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
        overflow: auto;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0.5s, opacity 0.5s linear;
        background-color: white;
        width: 17vw;
        max-height: 85vh;
        .help-title {
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-size: 14px;
            padding-left: 20px;
            margin: 10px;
            color: #303030;
            font-weight: bold;
        }
        .box {
            font-size: 14px;
            .title {
                background-color: #4285F4;
                margin: 0 0 5px;
                padding: 10px;
                color: #FFF;
                font-weight: bold;
            }
            .item {
                font-weight: bold;
                font-size: 12px;
                padding-left: 20px;
                display: flex;
                color: #303030;
                line-height: 25px;
                padding-bottom: 3px;
                .icon {
                    margin-right: 5px;
                    width: 25px;
                    text-align: center;
                }
            }
        }
    }
    .CheckBoxMetric {  
        li {
            padding: 5px 40px;
        }
        .checkbox-item {
            background: #FFFFFF;
            border: 1px solid #4285F4;
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
            border-radius: 4px;
            height: 36px;
            padding: 10px 0 5px 10px;

        }
        .active-bgr {
            background: #4285F4;
            .note {
                color: #FFF;
            }
        }
        .checkbox-btn {
            display: none;
            min-width: 25px;
            width: 25px;
            span {
                padding: 0 0 7px;
            }
        }
        .item {
            font-weight: bold;
            font-size: 12px;
            display: flex;
            color: #303030;
            line-height: 25px;
        }
        .icon {
            height: 30px;
            margin-right: 10px;
            width: 25px;
            text-align: center; 
        }
        .note {
            width: 115px;
            color: #4285F4;
        }
    }
    .icon-item:hover {
        background-color: #88c6ed;
    }
    .toggleToolBar {
        visibility: visible;
        opacity: 1;
    }
    .LayerMap {
        padding: 0px 5px;
        max-height: 85vh;
        .layer-item {
            cursor: pointer;
            margin: 5px 0px;
            background-size: cover;
            background-repeat: no-repeat;
            height: 75px;
            .layer-item-title {
                background-color: #1976d2;
                color: white;
                padding: 5px 10px;
                font-size: 13px;
                font-weight: bold;
                width: 100px;
                font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            }
        }
        .select-layer {
            border: 1px solid;
        }
    }
}
.leaflet-control-layers{
    display: none;
}
